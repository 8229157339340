import { useCallback, useEffect, useRef, useState } from "react";

export function useLoadingState(
  loadObservable: boolean,
  defaultValue = false
): [boolean, () => void] {
  const [isLoading, setIsLoading] = useState(defaultValue);
  const isWatching = useRef<boolean>(false);

  useEffect(() => {
    if (isWatching.current) {
      setIsLoading(loadObservable);
      if (!loadObservable) isWatching.current = false;
    }
  }, [loadObservable]);

  const startLoad = useCallback(() => {
    isWatching.current = true;
  }, []);

  return [isLoading, startLoad];
}
