import { Popover } from "src/utils/components/Popover";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled from "styled-components/macro";

export const StyledHeader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: ${extractThemeValue("headerHeight")};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${extractThemeValue("background")};
  color: ${extractThemeValue("color")};
  padding: ${extractThemeValue("headerPadding")};
  z-index: 1;
`;

export const StyledAppMenuPopover = styled(Popover)`
  position: fixed;
  top: calc(${extractThemeValue("headerHeight")} + 2px);
  right: 20px;
  width: ${extractThemeValue("appMenuWidth")};
  background: ${extractThemeValue("backgroundPrimary")};
  color: ${extractThemeValue("color")};
  border-radius: ${extractThemeValue("appMenuBorderRadius")};
  overflow: hidden;
  z-index: 2;
`;
