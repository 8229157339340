import { Fragment } from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components/macro";
import { GlobalStyles } from "./assets/styles/global";
import { FormLayout } from "./components/Layouts/FormLayout";
import { MainLayout } from "./components/Layouts/MainLayout";
import { LoginPage } from "./components/Pages/LoginPage";
import { PasswordExpiredPage } from "./components/Pages/PasswordExpiredPage";
import { StudioPage } from "./components/Pages/StudioPage";
import { VodsPage } from "./components/Pages/VodsPage";
import { Store, StoreContext } from "./store";
import { usePreferedTheme } from "./utils/hooks/usePreferedTheme";

export const App = observer(function App() {
  const preferedTheme = usePreferedTheme();

  const store = useLocalObservable(
    () => new Store({ themeStore: { theme: preferedTheme } })
  );

  return (
    <Fragment>
      <ThemeProvider theme={store.themeStore.theme}>
        <GlobalStyles />
        <StoreContext.Provider value={store}>
          <BrowserRouter>
            <Routes>
              <Route
                path={store.links.home}
                element={
                  <MainLayout>
                    <VodsPage />
                  </MainLayout>
                }
              />
              {!store.profileStore.isAuthorized && (
                <Route
                  path={store.links.login}
                  element={
                    <FormLayout>
                      <LoginPage />
                    </FormLayout>
                  }
                />
              )}
              {store.profileStore.isAuthorized &&
                store.profileStore.isPasswordExpired && (
                  <Route
                    path={store.links.expired}
                    element={
                      <FormLayout>
                        <PasswordExpiredPage />
                      </FormLayout>
                    }
                  />
                )}
              {(!store.profileStore.isAuthorized ||
                store.profileStore.isPrivelleged) && (
                <Route
                  path={store.links.studio}
                  element={
                    <MainLayout>
                      <StudioPage />
                    </MainLayout>
                  }
                />
              )}
              <Route
                path="/profile"
                element={<Navigate to={store.links.profile} />}
              />
              <Route path="*" element={<Navigate to={store.links.home} />} />
            </Routes>
          </BrowserRouter>
        </StoreContext.Provider>
      </ThemeProvider>
    </Fragment>
  );
});
