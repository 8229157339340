import styled from "styled-components/macro";

export const StyledPanel = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%;
`;
