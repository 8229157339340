import styled from "styled-components/macro";
import { Icon } from "../Icon";

export const StyledInlineIcon = styled(Icon)`
  display: inline-block;
  user-select: none;
  width: 1.2em;
  height: 1.2em;
  color: inherit;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
