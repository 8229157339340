import { KeyboardEvent, useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { Loader } from "src/components/common/Loader";
import { useStore } from "src/store";
import { Error } from "src/utils/components/Error";
import { Info } from "src/utils/components/Info";
import { checkPasswordWeakness } from "src/utils/functions/checkPasswordWeekness";
import { useInputHandler } from "src/utils/hooks/useInputHandler";
import {
  StyledButton,
  StyledButtonsSection,
  StyledContainer,
  StyledInput,
  StyledInputLabel,
  StyledInputWrapper,
  StyledTitle,
} from "./styles";

export const PasswordExpiredPage = observer(function PasswordExpiredPage() {
  const { profileStore, links } = useStore();

  const [password, setPassword] = useState("");
  const [repetition, setRepetition] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChangeExpiredPassword = useCallback(() => {
    if (!password.trim() || !repetition.trim()) return;

    if (password !== repetition) return setErrorMessage("Пароли не совпадают");

    if (!checkPasswordWeakness(password))
      return setErrorMessage("Пароль слабый");

    setErrorMessage(null);
    profileStore.changeExpiredPassword(password);
  }, [password, repetition, setErrorMessage]);

  const handleChangeExpiredPasswordViaKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") handleChangeExpiredPassword();
    },
    [handleChangeExpiredPassword]
  );

  if (profileStore.isLoading) return <Loader />;

  if (!profileStore.isPasswordExpired) return <Navigate to={links.home} />;

  return (
    <StyledContainer>
      <StyledTitle>Смена пароля</StyledTitle>
      <Info>Пароль был просрочен и должен быть заменен</Info>
      <StyledInputWrapper>
        <StyledInputLabel>Пароль</StyledInputLabel>
        <StyledInput
          type="password"
          value={password}
          onChange={useInputHandler(setPassword)}
          onKeyDown={handleChangeExpiredPasswordViaKeyDown}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <StyledInputLabel>Повторите пароль</StyledInputLabel>
        <StyledInput
          type="password"
          value={repetition}
          onChange={useInputHandler(setRepetition)}
          onKeyDown={handleChangeExpiredPasswordViaKeyDown}
        />
      </StyledInputWrapper>
      {errorMessage && <Error>{errorMessage}</Error>}
      <StyledButtonsSection>
        <StyledButton
          onClick={handleChangeExpiredPassword}
          disabled={!password.trim()}
        >
          Сменить пароль
        </StyledButton>
      </StyledButtonsSection>
    </StyledContainer>
  );
});
