import { action, computed, makeObservable, observable } from "mobx";
import { BaseStore } from "src/utils/classes/BaseStore";
import { Api } from "../Api";
import { ProfileStore } from "../ProfileStore";
import { Options, VodData, VodEditParams } from "./types";

export class VodsStore extends BaseStore {
  @observable
  public list: VodData[] = [];

  private api: Api;

  private profileStore: ProfileStore;

  public constructor(options: Options) {
    super();
    makeObservable(this);
    this.api = options.api;
    this.profileStore = options.profileStore;
  }

  @computed
  public get isListEmpty() {
    return this.list.length === 0;
  }

  @action.bound
  public getVodById(id: string) {
    return this.list.find((vod) => vod.id === id) ?? null;
  }

  @action.bound
  public async load() {
    await this.processWithLoad(async () =>
      this.setVods(await this.loadVodsData())
    );
  }

  @action.bound
  public async changeVodVisibility(vodId: string, visibility: boolean) {
    await this.processWithLowOrderLoad(async () => {
      const changedVod = await this.api.changeVodVisibility(vodId, visibility);
      this.list.splice(
        this.list.findIndex((vod) => vod.id === vodId),
        1,
        changedVod
      );
    });
  }

  @action.bound
  public async deleteVod(vodId: string) {
    await this.processWithLoad(async () => {
      await this.api.deleteVod(vodId);
      this.setVods(this.list.filter((vod) => vod.id !== vodId));
    });
  }

  @action.bound
  public async uploadPreview(vodId: string, preview: File) {
    await this.processWithLowOrderLoad(async () => {
      const changedVod = await this.api.uploadPreview(vodId, preview);
      this.list.splice(
        this.list.findIndex((vod) => vod.id === vodId),
        1,
        changedVod
      );
    });
  }

  @action.bound
  public async editVod(vodId: string, vodEditParams: VodEditParams) {
    await this.processWithLowOrderLoad(async () => {
      const changedVod = await this.api.editVod(vodId, vodEditParams);
      this.list.splice(
        this.list.findIndex((vod) => vod.id === vodId),
        1,
        changedVod
      );
    });
  }

  @action
  private setVods(vods: VodData[]) {
    this.list = vods;
  }

  private async loadVodsData(): Promise<VodData[]> {
    if (this.api.hasCredentials) {
      return await this.api.fetchVodsAsOwner("satanmate_");
    } else {
      return await this.api.fetchVods("satanmate_");
    }
  }
}
