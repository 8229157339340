import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Loader } from "src/components/common/Loader";
import { VodPreview } from "src/components/VodPreview";
import { useStore } from "src/store";
import { StyledContainer, StyledGrid, StyledTitle } from "./styles";

export const VodsPage = observer(function VodsPage() {
  const { vodsStore, profileStore } = useStore();

  useEffect(() => {
    if (!profileStore.isLoading) vodsStore.load();
  }, [profileStore.isLoading]);

  return (
    <StyledContainer>
      <StyledTitle>SATANMATE_</StyledTitle>
      {vodsStore.isLoading && <Loader />}
      {!vodsStore.isLoading && vodsStore.isListEmpty && (
        <StyledTitle>Водов не найдено</StyledTitle>
      )}
      {!vodsStore.isLoading && !vodsStore.isListEmpty && (
        <StyledGrid>
          {vodsStore.list.map((vod) => (
            <VodPreview key={vod.id} vod={vod} />
          ))}
        </StyledGrid>
      )}
    </StyledContainer>
  );
});
