export const checkPasswordWeakness = (password: string) => {
  const alphabet = "qwertyuiopasdfghjklzxcvbnm";
  const digits = "0123456789";
  const specials = "!@#$%^&*()_-+=|/.,:;[]{}";

  let includesCapitalLetters = false;
  let includesLowercaseLetters = false;
  let includesDigits = false;
  let includesSpecialSymbols = false;

  if (password.length < 8) return false;

  for (let i = 0; i < password.length; i++) {
    if (alphabet.toUpperCase().includes(password[i]))
      includesCapitalLetters = true;
    else if (alphabet.includes(password[i])) includesLowercaseLetters = true;
    else if (digits.includes(password[i])) includesDigits = true;
    else if (specials.includes(password[i])) includesSpecialSymbols = true;
  }

  return (
    includesCapitalLetters &&
    includesLowercaseLetters &&
    includesDigits &&
    includesSpecialSymbols
  );
};
