import styled, { keyframes } from "styled-components/macro";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledIconLoader = styled.div`
  user-select: none;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 2px solid;
  border-bottom-color: transparent;
  color: inherit;
  animation: ${spin} 1s linear infinite;
`;
