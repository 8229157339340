import { mixinHover } from "src/assets/styles/mixins";
import { addAlphaToRgb } from "src/utils/functions/addAlphaToRgb";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled, { css } from "styled-components/macro";

export const StyledIconButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 5px;
  width: 33px;
  height: 33px;
  border-radius: 10px;
  background: ${extractThemeValue("backgroundSecondary")};
  cursor: pointer;
  color: inherit;
  transition: background 0.1s ease-out;

  ${mixinHover(css`
    background: ${extractThemeValue("backgroundSecondary", (value) =>
      addAlphaToRgb(value, 0.5)
    )};
  `)}
`;
