import { memo } from "react";
import { createPortal } from "react-dom";
import { StyledBackdrop, StyledModal } from "./styles";
import { Props } from "./types";

export const Modal = memo(function Modal(props: Props) {
  const { visible, onHide, children, ...otherProps } = props;

  const handleHide = () => {
    if (onHide) onHide();
  };

  if (!visible) return null;

  return createPortal(
    <StyledModal {...otherProps}>
      <StyledBackdrop onClick={handleHide} />
      {children}
    </StyledModal>,
    document.body
  );
});
