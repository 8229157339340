import { action, makeObservable, observable } from "mobx";
import { ProcessWithLoadCallback } from "./types";

export class BaseStore {
  @observable
  public isLoading = false;

  @observable
  public isLowOrderLoading = false;

  @observable
  public isError = false;

  public constructor() {
    makeObservable(this);
  }

  @action
  public setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  public setIsLowOrderLoading(isLowOrderLoading: boolean) {
    this.isLowOrderLoading = isLowOrderLoading;
  }

  @action
  public setIsError(isError: boolean) {
    this.isError = isError;
  }

  @action
  protected async processWithLoad(
    callback: ProcessWithLoadCallback,
    onErrorCallback?: VoidFunction
  ) {
    this.setIsError(false);
    this.setIsLoading(true);
    this.setIsLowOrderLoading(true);
    try {
      await callback();
    } catch (error) {
      this.setIsError(true);
      onErrorCallback?.();
    } finally {
      this.setIsLoading(false);
      this.setIsLowOrderLoading(false);
    }
  }

  @action
  protected async processWithLowOrderLoad(
    callback: ProcessWithLoadCallback,
    onErrorCallback?: VoidFunction
  ) {
    this.setIsError(false);
    this.setIsLowOrderLoading(true);
    try {
      await callback();
    } catch (error) {
      this.setIsError(true);
      onErrorCallback?.();
    } finally {
      this.setIsLowOrderLoading(false);
    }
  }
}
