import { MouseEvent, useState } from "react";
import { observer } from "mobx-react-lite";
import { ReactComponent as SettingsIcon } from "src/assets/icons/settingsIcon.svg";
import { AppMenu } from "../AppMenu";
import { Icon } from "../common/Icon";
import { StyledAppMenuPopover, StyledHeader } from "./styles";

export const Header = observer(function Header() {
  const [isAppMenuVisible, setIsAppMenuVisible] = useState(false);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    setIsAppMenuVisible((previous) => !previous);
  };

  const hideAppMenu = () => {
    setIsAppMenuVisible(false);
  };

  return (
    <>
      <StyledHeader>
        <Icon onClick={handleClick}>
          <SettingsIcon />
        </Icon>
      </StyledHeader>
      <StyledAppMenuPopover visible={isAppMenuVisible} onHide={hideAppMenu}>
        <AppMenu />
      </StyledAppMenuPopover>
    </>
  );
});
