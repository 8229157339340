import { KeyboardEvent, useCallback, useState } from "react";
import { observer } from "mobx-react-lite";
import { Navigate, useNavigate } from "react-router-dom";
import { Loader } from "src/components/common/Loader";
import { useStore } from "src/store";
import { useInputHandler } from "src/utils/hooks/useInputHandler";
import {
  StyledButton,
  StyledButtonsSection,
  StyledContainer,
  StyledForm,
  StyledInlineButton,
  StyledInput,
  StyledInputLabel,
  StyledInputWrapper,
  StyledTitle,
} from "./styles";

export const LoginPage = observer(function LoginPage() {
  const { profileStore, links } = useStore();

  const [channel, setChannel] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = useCallback(() => {
    if (!channel.trim() || !password.trim()) return;
    profileStore.signIn(channel, password);
  }, [channel, password]);

  const handleLoginViaKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") handleLogin();
    },
    [handleLogin]
  );

  const returnHome = useCallback(() => navigate(links.home), []);

  if (profileStore.isLoading) return <Loader />;

  if (profileStore.isPasswordExpired) return <Navigate to={links.expired} />;

  if (profileStore.isAuthorized) return <Navigate to={links.home} />;

  return (
    <StyledContainer>
      <StyledTitle>Вход в профиль</StyledTitle>
      <StyledForm>
        <StyledInputWrapper>
          <StyledInputLabel>Канал</StyledInputLabel>
          <StyledInput
            value={channel}
            onChange={useInputHandler(setChannel)}
            onKeyDown={handleLoginViaKeyDown}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <StyledInputLabel>Пароль</StyledInputLabel>
          <StyledInput
            type="password"
            value={password}
            onChange={useInputHandler(setPassword)}
            onKeyDown={handleLoginViaKeyDown}
          />
        </StyledInputWrapper>
      </StyledForm>
      <StyledButtonsSection>
        <StyledButton
          onClick={handleLogin}
          disabled={!channel.trim() || !password.trim()}
        >
          Войти
        </StyledButton>
        <StyledInlineButton onClick={returnHome}>
          Вернуться к списку
        </StyledInlineButton>
      </StyledButtonsSection>
    </StyledContainer>
  );
});
