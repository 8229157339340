import { FC, FormEvent, memo, useCallback } from "react";
import cn from "classnames";
import { StyledSwitch, StyledSwitchWrapper } from "./styles";
import { Props } from "./types";

export const Switch: FC<Props> = memo(function Switch(props) {
  const { checked, onChange, ...otherProps } = props;

  const handleClick = useCallback(
    (event: FormEvent<HTMLDivElement>) => {
      if (onChange) onChange(event);
    },
    [onChange]
  );

  return (
    <StyledSwitch
      {...otherProps}
      className={cn({ checked: checked })}
      onClick={handleClick}
    >
      <StyledSwitchWrapper />
    </StyledSwitch>
  );
});
