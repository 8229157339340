import { FC, memo, useCallback } from "react";
import { DateTime } from "luxon";
import { HiddenBadge } from "../common/badges/HiddenBadge";
import { LiveBadge } from "../common/badges/LiveBadge";
import {
  StyledBadgeWrapper,
  StyledPreview,
  StyledPreviewContent,
  StyledPreviewDate,
  StyledPreviewGame,
  StyledPreviewImageWrapper,
  StyledPreviewTitle,
} from "./styles";
import { Props } from "./types";

export const VodPreview: FC<Props> = memo(function VodPreview(props: Props) {
  const { vod, ...otherProps } = props;

  const openVodPage = useCallback(() => {
    if (vod.isRecording)
      return (window.location.href = `https://twitch.tv/${vod.owner.channel}`);
    window.location.href = vod.videoUrl;
  }, [vod]);

  return (
    <StyledPreview {...otherProps} onClick={openVodPage}>
      <StyledPreviewImageWrapper>
        {vod.previewUrl && <img src={vod.previewUrl} alt="Preview" />}
        {(vod.isRecording || vod.isHidden) && (
          <StyledBadgeWrapper>
            {vod.isHidden && <HiddenBadge />}
            {vod.isRecording && <LiveBadge />}
          </StyledBadgeWrapper>
        )}
      </StyledPreviewImageWrapper>
      <StyledPreviewContent>
        <StyledPreviewTitle>{vod.title}</StyledPreviewTitle>
        <StyledPreviewGame>{vod.game}</StyledPreviewGame>
        <StyledPreviewDate>
          {DateTime.fromISO(vod.createdAt)
            .setZone("system")
            .toFormat("dd MMMM yyyy, HH:mm")}
        </StyledPreviewDate>
      </StyledPreviewContent>
    </StyledPreview>
  );
});
