// eslint-disable-next-line no-restricted-imports

export enum LocalStorageItems {
  AppTheme = "app_theme",
}

export enum ThemeNames {
  Light = "light",
  Dark = "dark",
}
