import { MouseEvent, FC, memo, useCallback, useState } from "react";
import { DateTime } from "luxon";
import { ReactComponent as MoreIcon } from "src/assets/icons/moreVerticalIcon.svg";
import { useLoadingState } from "src/utils/hooks/useLoadingState";
import { HiddenBadge } from "../common/badges/HiddenBadge";
import { LiveBadge } from "../common/badges/LiveBadge";
import { Icon } from "../common/Icon";
import { IconButton } from "../common/IconButton";
import { IconLoader } from "../common/IconLoader";
import { VodListElementDropdownMenu } from "./components/VodListElementDropdownMenu";
import {
  StyledElementTitle,
  StyledElementImageWrapper,
  StyledVodListElement,
  StyledElementMeta,
  StyledElementContentContainer,
  StyledElementGameCategory,
  StyledElementControls,
} from "./styles";
import { Props } from "./types";

export const VodListElement: FC<Props> = memo(function VodListElement(props) {
  const {
    vod,
    onVisibilityChange,
    onDeleteConfirmation,
    onPreviewReplacement,
    onEdit,
    isLoading = false,
    ...otherProps
  } = props;

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isInLoadPreview, startLoadPreview] = useLoadingState(isLoading);
  const [isInLoadVisibility, startLoadVisibility] = useLoadingState(isLoading);

  const openMenu = (event: MouseEvent) => {
    event.stopPropagation();
    setIsMenuVisible((previous) => !previous);
  };

  const hideMenu = () => {
    setIsMenuVisible(false);
  };

  const openVodPage = useCallback(() => {
    if (vod.isRecording)
      return window.open(`https://twitch.tv/${vod.owner.channel}`);

    window.open(vod.videoUrl);
  }, [vod]);

  const openPreviewReplacement = useCallback(() => {
    if (onPreviewReplacement) {
      startLoadPreview();
      onPreviewReplacement(vod.id);
    }
  }, [vod, onPreviewReplacement]);

  const openEdit = useCallback(() => {
    if (onEdit) onEdit(vod.id);
  }, [vod]);

  const switchVisibility = useCallback(() => {
    if (onVisibilityChange && !isInLoadVisibility) {
      startLoadVisibility();
      onVisibilityChange(vod.id, !vod.isHidden);
    }
  }, [vod, onVisibilityChange, startLoadVisibility, isInLoadVisibility]);

  const openDeleteConfirmation = useCallback(() => {
    if (onDeleteConfirmation) onDeleteConfirmation(vod.id);
  }, [vod, onDeleteConfirmation]);

  return (
    <StyledVodListElement {...otherProps}>
      <StyledElementImageWrapper>
        {vod.previewUrl && !isInLoadPreview && (
          <img src={vod.previewUrl} alt="Preview" />
        )}
        {isInLoadPreview && <IconLoader />}
      </StyledElementImageWrapper>
      <StyledElementContentContainer>
        <StyledElementTitle>{vod.title}</StyledElementTitle>
        <StyledElementMeta>
          {DateTime.fromISO(vod.createdAt)
            .setZone("system")
            .toFormat("dd MMMM yyyy, HH:mm")}
          {vod.isHidden && <HiddenBadge />}
          {vod.isRecording && <LiveBadge />}
        </StyledElementMeta>
      </StyledElementContentContainer>
      <StyledElementGameCategory>{vod.game}</StyledElementGameCategory>
      <StyledElementControls>
        <IconButton onClick={openMenu}>
          <Icon>
            <MoreIcon />
          </Icon>
        </IconButton>
      </StyledElementControls>
      <VodListElementDropdownMenu
        visible={isMenuVisible}
        onHide={hideMenu}
        onOpen={openVodPage}
        onPreviewChange={openPreviewReplacement}
        onChange={openEdit}
        onVisibilityChange={switchVisibility}
        isVodHidden={vod.isHidden}
        isVisibilityLoading={isInLoadVisibility}
        onDelete={openDeleteConfirmation}
      />
    </StyledVodListElement>
  );
});
