import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemedStyledProps,
} from "styled-components/macro";

export type Styles =
  | string
  | FlattenSimpleInterpolation
  | FlattenInterpolation<
      ThemedStyledProps<React.HTMLAttributes<HTMLDivElement>, DefaultTheme>
    >;

export const mixinScreenMaxWidth = (screenWidth: string, styles: Styles) => css`
  @media screen and (max-width: ${screenWidth}) {
    ${styles}
  }
`;

export const mixinScreenMinWidth = (screenWidth: string, styles: Styles) => css`
  @media screen and (min-width: ${screenWidth}) {
    ${styles}
  }
`;

export const mixinTouchDevice = (styles: Styles) => css`
  @media (hover: none) {
    ${styles}
  }
`;

export const mixinNotTouchDevice = (styles: Styles) => css`
  @media (hover: hover) {
    ${styles}
  }
`;

export const mixinHover = (styles: Styles) =>
  mixinNotTouchDevice(css`
    &:hover {
      ${styles}
    }
  `);
