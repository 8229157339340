import { ChangeEvent, memo, useMemo, useRef, useState } from "react";
import { Info } from "../Info";
import { defaultAccept } from "./constants";
import {
  StyledFileUplaoder,
  StyledHiddenInput,
  StyledIcon,
  StyledImageWrapper,
} from "./styles";
import { Props } from "./types";

export const FileUploader = memo(function FileUploader(props: Props) {
  const {
    accept = defaultAccept,
    icon,
    onFileChange,
    description,
    wrapDescription = false,
    ...otherProps
  } = props;

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [image, setImage] = useState<File | null>(null);
  const imagePreview = useMemo(
    () => (image ? URL.createObjectURL(image) : ""),
    [image]
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) return;
    const file = event.target.files[0];
    setImage(file);
    if (onFileChange) onFileChange(file);
  };

  const handleFileChoice = () => {
    inputRef.current?.click();
  };

  return (
    <StyledFileUplaoder {...otherProps}>
      <StyledHiddenInput
        type="file"
        accept={accept}
        ref={inputRef}
        onChange={handleFileChange}
      />
      {description && (
        <Info wrap={wrapDescription} style={{ width: "100%" }}>
          {description}
        </Info>
      )}
      {!image ? (
        <StyledIcon onClick={handleFileChoice}>{icon}</StyledIcon>
      ) : (
        <StyledImageWrapper onClick={handleFileChoice}>
          <img src={imagePreview} />
        </StyledImageWrapper>
      )}
    </StyledFileUplaoder>
  );
});
