import { mixinScreenMaxWidth } from "src/assets/styles/mixins";
import { screenL, screenS } from "src/assets/styles/variables";
import styled, { css } from "styled-components/macro";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1360px;
  width: 100%;
  padding: 0px 20px;
  color: ${({ theme }) => theme.color};
`;

export const StyledTitle = styled.div`
  font-size: 38px;
  font-weight: 700;
  user-select: none;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  height: 100%;

  ${mixinScreenMaxWidth(
    screenL,
    css`
      grid-template-columns: repeat(2, 1fr);
    `
  )}

  ${mixinScreenMaxWidth(
    screenS,
    css`
      grid-template-columns: repeat(1, 1fr);
    `
  )}
`;
