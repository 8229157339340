import styled from "styled-components/macro";

export const StyledSwitchWrapper = styled.div`
  position: relative;
  pointer-events: none;
  width: 2.75rem;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.colorPrimary};
  border-radius: 20px;
  transition: background 0.2s ease-out;

  &::after {
    content: "";
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translate(0%, -50%);
    width: 17px;
    height: 17px;
    border-radius: 50%;
    transition: left 0.1s ease-out;
    background-color: ${({ theme }) => theme.color};
  }
`;

export const StyledSwitch = styled.div`
  position: relative;
  height: 1.5rem;
  cursor: pointer;

  &.checked > ${StyledSwitchWrapper}::after {
    left: calc(100% - 1.2rem);
  }
`;
