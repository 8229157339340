import { createGlobalStyle } from "styled-components/macro";
import Montserrat300Woff from "../fonts/montserrat-v25-cyrillic_latin-300.woff";
import Montserrat300Woff2 from "../fonts/montserrat-v25-cyrillic_latin-300.woff2";
import Montserrat500Woff from "../fonts/montserrat-v25-cyrillic_latin-500.woff";
import Montserrat500Woff2 from "../fonts/montserrat-v25-cyrillic_latin-500.woff2";
import Montserrat600Woff from "../fonts/montserrat-v25-cyrillic_latin-600.woff";
import Montserrat600Woff2 from "../fonts/montserrat-v25-cyrillic_latin-600.woff2";
import Montserrat700Woff from "../fonts/montserrat-v25-cyrillic_latin-700.woff";
import Montserrat700Woff2 from "../fonts/montserrat-v25-cyrillic_latin-700.woff2";
import Montserrat800Woff from "../fonts/montserrat-v25-cyrillic_latin-800.woff";
import Montserrat800Woff2 from "../fonts/montserrat-v25-cyrillic_latin-800.woff2";
import Montserrat900Woff from "../fonts/montserrat-v25-cyrillic_latin-900.woff";
import Montserrat900Woff2 from "../fonts/montserrat-v25-cyrillic_latin-900.woff2";
import Montserrat400Woff from "../fonts/montserrat-v25-cyrillic_latin-regular.woff";
import Montserrat400Woff2 from "../fonts/montserrat-v25-cyrillic_latin-regular.woff2";

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url(${Montserrat300Woff2}) format('woff2'),
        url(${Montserrat300Woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(${Montserrat400Woff2}) format('woff2'),
        url(${Montserrat400Woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url(${Montserrat500Woff2}) format('woff2'),
        url(${Montserrat500Woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url(${Montserrat600Woff2}) format('woff2'),
        url(${Montserrat600Woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url(${Montserrat700Woff2}) format('woff2'),
        url(${Montserrat700Woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url(${Montserrat800Woff2}) format('woff2'),
        url(${Montserrat800Woff}) format('woff');
  }

  @font-face {
    font-display: swap;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url(${Montserrat900Woff2}) format('woff2'),
        url(${Montserrat900Woff}) format('woff');
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  html {
    font-family: 'Montserrat';
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.background};
  }

  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  *,
  *::after,
  *::before {
    font-family: inherit;
    box-sizing: inherit;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  #root {
    position: relative;
  }

  button {
    border: none;
    font-family: inherit;
    background-color: inherit;
  }
`;
