import { mixinHover } from "src/assets/styles/mixins";
import { Icon } from "src/components/common/Icon";
import { addAlphaToRgb } from "src/utils/functions/addAlphaToRgb";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled, { css } from "styled-components/macro";

export const StyledFileUplaoder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

export const StyledHiddenInput = styled.input`
  display: none;
`;

export const StyledIcon = styled(Icon)`
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${extractThemeValue("colorSecondary")};
  background: ${extractThemeValue("backgroundSecondary")};
  border: 2px dashed transparent;
  border-radius: 15px;
  transition: background 0.2s ease-out, border 0.2s ease-out;

  ${mixinHover(css`
    background: ${extractThemeValue("backgroundSecondary", (value) =>
      addAlphaToRgb(value, 0.7)
    )};
    border: 2px dashed ${extractThemeValue("colorSecondary")};
  `)}
`;

export const StyledImageWrapper = styled.div`
  width: 100%;
  height: auto;
  cursor: pointer;

  & > img {
    width: 100%;
    height: 100%;
  }
`;
