import { Context, createContext, useContext } from "react";
import { makeAutoObservable } from "mobx";
import { Api } from "./Api";
import { ProfileStore } from "./ProfileStore";
import { ThemeStore } from "./themeStore";
import { Options } from "./types";
import { VodsStore } from "./VodsStore";

export class Store {
  public themeStore: ThemeStore;

  public vodsStore: VodsStore;

  public profileStore: ProfileStore;

  public api: Api;

  public isLoading = false;

  public constructor(options: Options) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.api = new Api();
    this.themeStore = new ThemeStore(options.themeStore);
    this.profileStore = new ProfileStore({ api: this.api });
    this.vodsStore = new VodsStore({
      api: this.api,
      profileStore: this.profileStore,
    });
    this.initData();
  }

  public get links() {
    return {
      home: "/",
      login: "/profile/login",
      expired: "/profile/password_expired",
      profile: "/profile/me",
      studio: "/profile/studio",
      analytics: "/profile/analytics",
    };
  }

  private async initData() {
    this.profileStore.init();
  }
}

export const StoreContext = createContext<Store | null>(null);
export const useStore = () => useContext(StoreContext as Context<Store>);
