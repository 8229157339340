import { DefaultTheme } from "styled-components/macro";
import { GeneralTheme } from "./types";

export const generalTheme: GeneralTheme = {
  headerHeight: "48px",
  headerPadding: "10px 20px",
  appMenuInputPadding: "10px 20px",
  appMenuPadding: "10px 0px",
  appMenuWidth: "300px",
  appMenuBorderRadius: "12px",
  formsBorderRadius: "15px",
  formsPadding: "40px",
};

export const lightTheme: DefaultTheme = {
  ...generalTheme,
  background: "rgb(250, 250, 250)",
  backgroundPrimary: "rgb(245, 245, 245)",
  backgroundSecondary: "rgb(227, 227, 227)",
  color: "rgb(30, 30, 30)",
  colorPrimary: "rgb(37, 36, 34)",
  colorSecondary: "rgb(173, 173, 184)",
  colorAccent: "rgb(105, 152, 232)",
  colorAccentHover: "rgb(105, 152, 232)",
  info: "rgb(145,70,255)",
  error: "rgb(224, 30, 55)",
  live: "rgb(216, 0, 50)",
};

export const darkTheme: DefaultTheme = {
  ...generalTheme,
  background: "rgb(30, 30, 30)",
  backgroundPrimary: "rgb(37, 36, 34)",
  backgroundSecondary: "rgb(60, 60, 60)",
  color: "rgb(250, 250, 250)",
  colorPrimary: "rgb(245, 245, 245)",
  colorSecondary: "rgb(173, 173, 184)",
  colorAccent: "rgb(105, 152, 232)",
  colorAccentHover: "rgb(105, 152, 232)",
  info: "rgb(145,70,255)",
  error: "rgb(224, 30, 55)",
  live: "rgb(216, 0, 50)",
};
