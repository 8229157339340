import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { Loader } from "src/components/common/Loader";
import { VodListElement } from "src/components/VodListElement";
import { useStore } from "src/store";
import { DeleteModal } from "./components/DeleteModal";
import { EditModal } from "./components/EditModal";
import { PreviewModal } from "./components/PreviewModal";
import { StyledContainer, StyledGrid, StyledTitle } from "./styles";

export const StudioPage = observer(function StudioPage() {
  const { vodsStore, profileStore, links } = useStore();

  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedVodId, setSelectedVodId] = useState<null | string>(null);

  const selectedVod = useMemo(
    () => (selectedVodId ? vodsStore.getVodById(selectedVodId) : null),
    [selectedVodId]
  );

  useEffect(() => {
    if (profileStore.isAuthorized && !profileStore.isLoading) vodsStore.load();
  }, [profileStore.isLoading, profileStore.isAuthorized]);

  const handlePreviewReplacement = (vodId: string) => {
    setSelectedVodId(vodId);
    setIsPreviewModalVisible(true);
  };

  const handleEdit = (vodId: string) => {
    setSelectedVodId(vodId);
    setIsEditModalVisible(true);
  };

  const handleDeleteConfirmation = (vodId: string) => {
    setSelectedVodId(vodId);
    setIsDeleteModalVisible(true);
  };

  const closeModal = () => {
    setSelectedVodId(null);
    setIsPreviewModalVisible(false);
    setIsDeleteModalVisible(false);
    setIsEditModalVisible(false);
  };

  const handleReplacePreview = async (preview: File) => {
    if (!selectedVodId) return;
    await vodsStore.uploadPreview(selectedVodId, preview);
    if (vodsStore.isError) return;

    setSelectedVodId(null);
    setIsPreviewModalVisible(false);
  };

  const handleEditVod = async (title: string, game: string) => {
    if (!selectedVodId) return;
    await vodsStore.editVod(selectedVodId, { title, game });
    if (vodsStore.isError) return;

    setSelectedVodId(null);
    setIsEditModalVisible(false);
  };

  const handleDeleteVod = async () => {
    if (!selectedVodId) return;
    await vodsStore.deleteVod(selectedVodId);
    if (vodsStore.isError) return;

    setSelectedVodId(null);
    setIsDeleteModalVisible(false);
  };

  if (profileStore.isLoading) return <Loader />;

  if (!profileStore.isPrivelleged) return <Navigate to={links.home} />;

  return (
    <>
      <StyledContainer>
        <StyledTitle>Видеостудия</StyledTitle>
        {vodsStore.isLoading && <Loader />}
        {!vodsStore.isLoading && vodsStore.isListEmpty && (
          <StyledTitle>Еще не добавлено ни одного материала</StyledTitle>
        )}
        {!vodsStore.isLoading && !vodsStore.isListEmpty && (
          <StyledGrid>
            {vodsStore.list.map((vod) => (
              <VodListElement
                key={vod.id}
                vod={vod}
                onEdit={handleEdit}
                onVisibilityChange={vodsStore.changeVodVisibility}
                onDeleteConfirmation={handleDeleteConfirmation}
                onPreviewReplacement={handlePreviewReplacement}
                isLoading={vodsStore.isLowOrderLoading}
              />
            ))}
          </StyledGrid>
        )}
      </StyledContainer>
      <PreviewModal
        vod={selectedVod}
        visible={isPreviewModalVisible}
        isLoading={vodsStore.isLowOrderLoading}
        isError={vodsStore.isError}
        onHide={closeModal}
        onReplace={handleReplacePreview}
      />
      <EditModal
        vod={selectedVod}
        visible={isEditModalVisible}
        isLoading={vodsStore.isLowOrderLoading}
        isError={vodsStore.isError}
        onHide={closeModal}
        onEdit={handleEditVod}
      />
      <DeleteModal
        vod={selectedVod}
        visible={isDeleteModalVisible}
        isLoading={vodsStore.isLoading}
        isError={vodsStore.isError}
        onHide={closeModal}
        onDelete={handleDeleteVod}
      />
    </>
  );
});
