import { FC, HTMLAttributes } from "react";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { Header } from "src/components/Header";
import { useStore } from "src/store";
import { StyledMainLayout } from "./styles";

export const MainLayout: FC<HTMLAttributes<HTMLDivElement>> = observer(
  function MainLayout({ children, ...otherProps }) {
    const { profileStore, links } = useStore();

    if (profileStore.isPasswordExpired) return <Navigate to={links.expired} />;

    return (
      <StyledMainLayout {...otherProps}>
        <Header />
        {children}
      </StyledMainLayout>
    );
  }
);
