import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled from "styled-components/macro";

export const StyledLiveBadge = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0.25em 0.5em;
  gap: 0.25em;
  border-radius: 1em;
  color: #ffffff;
  background: ${extractThemeValue("live")};

  &::before {
    content: "";
    position: relative;
    width: 0.75em;
    height: 0.75em;
    background: #ffffff;
    border-radius: 100%;
  }
`;
