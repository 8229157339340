import { memo } from "react";
import { StyledLoader } from "./styles";

export const Loader = memo(function Loader() {
  return (
    <StyledLoader>
      <div />
      <div />
      <div />
    </StyledLoader>
  );
});
