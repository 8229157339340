import { mixinHover } from "src/assets/styles/mixins";
import styled, { css } from "styled-components/macro";

export const StyledPreview = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  color: ${({ theme }) => theme.color};
  cursor: pointer;
  transition: transform 0.1s ease-out;
  overflow: hidden;

  ${mixinHover(css`
    transform: scale(1.05);
  `)}
`;

export const StyledPreviewImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledBadgeWrapper = styled.div`
  position: absolute;
  right: 7px;
  top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const StyledPreviewContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: none;
  gap: 5px;
  font-size: 20px;
  width: 100%;
  padding: 0px 10px;
  overflow: hidden;
`;

export const StyledPreviewTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledPreviewGame = styled.div`
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledPreviewDate = styled.div`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colorSecondary};
`;
