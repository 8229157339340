import { mixinHover } from "src/assets/styles/mixins";
import { addAlphaToRgb } from "src/utils/functions/addAlphaToRgb";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled, { css } from "styled-components/macro";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1360px;
  width: 100%;
  padding: 0px 20px;
  color: ${({ theme }) => theme.color};
`;

export const StyledTitle = styled.div`
  font-size: 38px;
  font-weight: 700;
  user-select: none;
`;

export const StyledGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 2px;
`;

export const StyledModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 425px;
  width: 100%;
  color: ${extractThemeValue("color")};
  background: ${extractThemeValue("backgroundPrimary")};
  padding: ${extractThemeValue("formsPadding")};
  border-radius: ${extractThemeValue("formsBorderRadius")};
  align-items: center;
`;

export const StyledModalTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  user-select: none;
  margin: 0;
`;

export const StyledModalList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  overflow-y: auto;
`;

export const StyledModalListElement = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  align-items: center;
`;

export const StyledModalListElementImageWrapper = styled.div`
  position: relative;
  width: 75px;
  height: auto;
  flex: none;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledModalListElementTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledModalListElementButtonsSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
`;

export const StyledModalListElementInlineButton = styled.button`
  font-size: 16px;
  color: ${extractThemeValue("colorSecondary")};
  background: transparent;
  cursor: pointer;
`;

export const StyledModalListElementWarningButton = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5em;
  font-size: 18px;
  font-weight: 500;
  color: rgb(250, 250, 250);
  background: ${extractThemeValue("error")};
  padding: 0.5em 1em;
  border-radius: 0.2em;
  cursor: pointer;
  transition: background 0.1s ease-out;

  ${mixinHover(css`
    background: ${extractThemeValue("error", (value) =>
      addAlphaToRgb(value, 0.6)
    )};
  `)}

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const StyledModalListElementAccentButton = styled(
  StyledModalListElementWarningButton
)`
  background: ${extractThemeValue("colorAccent")};

  ${mixinHover(css`
    background: ${extractThemeValue("colorAccent", (value) =>
      addAlphaToRgb(value, 0.6)
    )};
  `)}

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const StyledModalInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const StyledModalInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${extractThemeValue("colorSecondary")};
`;

export const StyledModalInput = styled.input`
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  outline: none;
  background: ${extractThemeValue("backgroundSecondary")};
  border: 2px solid ${extractThemeValue("backgroundSecondary")};
  color: ${extractThemeValue("color")};
  border-radius: 0.4em;
  padding: 0.5em 1em;
  transition: border 0.1s ease-out;

  ${mixinHover(css`
    border: 2px solid ${extractThemeValue("background")};
  `)}

  &:focus {
    border: 2px solid ${extractThemeValue("background")};
  }
`;
