import { FC, memo, useCallback, useMemo, useState } from "react";
import { ReactComponent as PreviewIcon } from "src/assets/icons/imageIcon.svg";
import { IconLoader } from "src/components/common/IconLoader";
import { Error } from "src/utils/components/Error";
import { FileUploader } from "src/utils/components/FileUploader";
import { Modal } from "src/utils/components/Modal";
import {
  StyledModalContainer,
  StyledModalTitle,
  StyledModalListElementButtonsSection,
  StyledModalListElementInlineButton,
  StyledModalListElementAccentButton,
} from "../../styles";
import { FileState, Props } from "./types";

export const PreviewModal: FC<Props> = memo(function PreviewModal(props) {
  const { vod, visible, isLoading, isError, onHide, onReplace } = props;

  const [preview, setPreview] = useState<null | File>(null);

  const validatePreview = useCallback((preview: File | null): FileState => {
    if (!preview) return { isValid: false, message: "Файл не добавлен" };

    if (!preview.type.match(/(jpg|jpeg|png|gif)$/))
      return {
        isValid: false,
        message: "Поддерживаемые типы файлов: jpg, png, gif",
      };

    if (preview.size <= 0)
      return {
        isValid: false,
        message: "Файл пустой",
      };

    if (preview.size > 8 * 1024 * 1024)
      return {
        isValid: false,
        message: "Максимальный размер файла 8мб",
      };

    return {
      isValid: true,
    };
  }, []);

  const fileState = useMemo(() => validatePreview(preview), [preview]);

  const handlePreviewReplaced = (newPreview: File) => {
    setPreview(newPreview);
  };

  const handleReplaceConfirmed = useCallback(() => {
    if (!fileState.isValid || !preview || !onReplace) return;
    onReplace(preview);
  }, [preview, fileState, onReplace]);

  if (!vod) return null;

  return (
    <Modal visible={visible} onHide={onHide}>
      <StyledModalContainer>
        <StyledModalTitle>Замена превью</StyledModalTitle>
        <FileUploader
          icon={<PreviewIcon />}
          description="Cоотношение сторон изображения должно быть 16:9"
          onFileChange={handlePreviewReplaced}
        />
        {(isError || !fileState.isValid) && (
          <Error>
            {fileState.message ? fileState.message : "Произошла ошибка"}
          </Error>
        )}
        <StyledModalListElementButtonsSection>
          <StyledModalListElementAccentButton
            disabled={!fileState.isValid || isLoading}
            onClick={handleReplaceConfirmed}
          >
            Заменить {isLoading && <IconLoader />}
          </StyledModalListElementAccentButton>
          <StyledModalListElementInlineButton onClick={onHide}>
            Отмена
          </StyledModalListElementInlineButton>
        </StyledModalListElementButtonsSection>
      </StyledModalContainer>
    </Modal>
  );
});
