import { mixinHover, mixinScreenMaxWidth } from "src/assets/styles/mixins";
import { screenS } from "src/assets/styles/variables";
import { Popover } from "src/utils/components/Popover";
import { addAlphaToRgb } from "src/utils/functions/addAlphaToRgb";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled, { css } from "styled-components/macro";

export const StyledButtonMenuPopover = styled(Popover)`
  position: absolute;
  width: 250px;
  display: flex;
  flex-direction: column;
  right: 0px;
  top: 15px;
  background: ${extractThemeValue("backgroundPrimary")};
  color: ${extractThemeValue("color")};
  border-radius: ${extractThemeValue("appMenuBorderRadius")};
  overflow: hidden;
  z-index: 1;

  ${mixinScreenMaxWidth(
    screenS,
    css`
      top: 0px;
    `
  )};
`;

export const StyledButtonMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${extractThemeValue("appMenuPadding")};
`;

export const StyledSeparator = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  margin: 5px 0px;
  background: ${extractThemeValue("backgroundSecondary")};
`;

export const StyledHoverableAppMenuElement = styled.div`
  width: 100%;
  padding: ${extractThemeValue("appMenuInputPadding")};
  transition: background 0.15s ease-out;
  cursor: pointer;

  ${mixinHover(css`
    background: ${extractThemeValue("backgroundSecondary")};
  `)}
`;

export const StyledButton = styled(StyledHoverableAppMenuElement)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: ${extractThemeValue("color")};
  padding: 0.5em 1em;
  border-radius: 0.2em;
  cursor: pointer;
  transition: background 0.1s ease-out;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const DeleteStyledButton = styled(StyledButton)`
  color: ${extractThemeValue("error")};

  ${mixinHover(css`
    background: ${extractThemeValue("error", (value) =>
      addAlphaToRgb(value, 0.1)
    )};
  `)}
`;
