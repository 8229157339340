import { observer } from "mobx-react-lite";
import { useStore } from "src/store";
import { ThemeNames } from "src/types";
import { LinkElement } from "./components/LinkElement";
import { SwitchElement } from "./components/SwitchElement";
import { StyledAppMenu, StyledSeparator } from "./styles";
import { Props } from "./types";

export const AppMenu = observer(function AppMenu(props: Props) {
  const { themeStore, profileStore } = useStore();

  const handleSignOut = () => {
    profileStore.signOut();
  };

  return (
    <StyledAppMenu {...props}>
      {profileStore.isAuthorized && profileStore.isPrivelleged && (
        <>
          <LinkElement label="Профиль" to="/profile/me" />
          <LinkElement label="Видеоматериалы" to="/profile/studio" />
          {/* <LinkElement label="Аналитика (soon)" to="/profile/analytics" /> */}
          <StyledSeparator />
        </>
      )}
      <SwitchElement
        label="Темное оформление"
        checked={themeStore.themeName === ThemeNames.Dark}
        onChange={themeStore.toggleTheme}
      />
      {profileStore.isAuthorized && (
        <>
          <StyledSeparator />
          <LinkElement label="Выйти" onClick={handleSignOut} />
        </>
      )}
    </StyledAppMenu>
  );
});
