import { memo, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Props } from "./types";

export const Popover = memo(function Popover(props: Props) {
  const { visible, onHide, portal = true, ...otherProps } = props;
  const popoverRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const hide = (event: MouseEvent) => {
      if (popoverRef.current?.contains(event.target as HTMLElement) || !onHide)
        return;
      onHide();
    };
    if (visible) document.addEventListener("click", hide);
    return () => {
      document.removeEventListener("click", hide);
    };
  }, [visible, onHide]);

  if (!visible) return null;

  if (!portal) return <div {...otherProps} ref={popoverRef} />;

  return createPortal(<div {...otherProps} ref={popoverRef} />, document.body);
});
