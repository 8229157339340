import { FC, HTMLAttributes, memo } from "react";
import { StyledFormLayout } from "./styles";

export const FormLayout: FC<HTMLAttributes<HTMLDivElement>> = memo(
  function FormLayout(props) {
    const { children, ...otherProps } = props;

    return <StyledFormLayout {...otherProps}>{children}</StyledFormLayout>;
  }
);
