import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { IconLoader } from "src/components/common/IconLoader";
import { Error } from "src/utils/components/Error";
import { Modal } from "src/utils/components/Modal";
import { useInputHandler } from "src/utils/hooks/useInputHandler";
import {
  StyledModalContainer,
  StyledModalTitle,
  StyledModalList,
  StyledModalListElementButtonsSection,
  StyledModalListElementInlineButton,
  StyledModalListElementAccentButton,
  StyledModalInputWrapper,
  StyledModalInputLabel,
  StyledModalInput,
} from "../../styles";
import { Props, State } from "./types";

export const EditModal: FC<Props> = memo(function EditModal(props) {
  const { vod, visible, isLoading, isError, onHide, onEdit } = props;

  const [title, setTitle] = useState(vod?.title ?? "");
  const [game, setGame] = useState(vod?.game ?? "");

  useEffect(() => {
    setTitle(vod?.title ?? "");
    setGame(vod?.game ?? "");
  }, [vod]);

  const validate = useCallback((title: string, game: string): State => {
    if (!title.trim())
      return { isValid: false, message: "Название не может быть пустым" };

    if (!game.trim())
      return { isValid: false, message: "Категория не может быть пустой" };

    return {
      isValid: true,
    };
  }, []);

  const state = useMemo(() => validate(title, game), [title, game]);

  const handleEdit = useCallback(() => {
    if (!state.isValid || !onEdit) return;
    onEdit(title, game);
  }, [state, title, game, onEdit]);

  if (!vod) return null;

  return (
    <Modal visible={visible} onHide={onHide}>
      <StyledModalContainer>
        <StyledModalTitle>Изменение</StyledModalTitle>
        <StyledModalList>
          <StyledModalInputWrapper>
            <StyledModalInputLabel>Название</StyledModalInputLabel>
            <StyledModalInput
              value={title}
              onChange={useInputHandler(setTitle)}
            />
          </StyledModalInputWrapper>
          <StyledModalInputWrapper>
            <StyledModalInputLabel>Категория</StyledModalInputLabel>
            <StyledModalInput
              value={game}
              onChange={useInputHandler(setGame)}
            />
          </StyledModalInputWrapper>
        </StyledModalList>
        {(isError || !state.isValid) && (
          <Error>{state.message ? state.message : "Произошла ошибка"}</Error>
        )}
        <StyledModalListElementButtonsSection>
          <StyledModalListElementAccentButton
            disabled={!state.isValid || isLoading}
            onClick={handleEdit}
          >
            Изменить {isLoading && <IconLoader />}
          </StyledModalListElementAccentButton>
          <StyledModalListElementInlineButton onClick={onHide}>
            Отмена
          </StyledModalListElementInlineButton>
        </StyledModalListElementButtonsSection>
      </StyledModalContainer>
    </Modal>
  );
});
