import { FC, memo, useCallback, useState } from "react";
import { ReactComponent as ChevronDownIcon } from "src/assets/icons/chevronDownIcon.svg";
import { ReactComponent as ChevronUpIcon } from "src/assets/icons/chevronUpIcon.svg";
import { ReactComponent as InfoIcon } from "src/assets/icons/infoIcon.svg";
import { InlineIcon } from "src/components/common/InlineIcon";
import {
  StyledInfo,
  StyledInfoContent,
  StyledInfoContentTitle,
  StyledInfoIcon,
} from "./styles";
import { Props } from "./types";

export const Error: FC<Props> = memo(function Error(props) {
  const { children, wrap = false, ...otherProps } = props;

  const [isWrapped, setIsWrapped] = useState(wrap);

  const switchWrapped = useCallback(() => {
    if (wrap) setIsWrapped((isWrapped) => !isWrapped);
  }, [wrap, setIsWrapped]);

  return (
    <StyledInfo {...otherProps} onClick={switchWrapped} $wrap={wrap}>
      <StyledInfoIcon>
        <InfoIcon />
      </StyledInfoIcon>
      <StyledInfoContent>
        {wrap && (
          <StyledInfoContentTitle>
            Ошибка
            <InlineIcon>
              {isWrapped ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </InlineIcon>
          </StyledInfoContentTitle>
        )}
        {(!wrap || !isWrapped) && children}
      </StyledInfoContent>
    </StyledInfo>
  );
});
