import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled from "styled-components/macro";

export const StyledHiddenBadge = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  padding: 0.25em 0.5em;
  gap: 0.25em;
  border-radius: 1em;
  color: #ffffff;
  background: ${extractThemeValue("backgroundSecondary")};
  color: ${extractThemeValue("colorPrimary")};

  & > svg {
    width: 1.25em;
    height: 1.25em;
  }
`;
