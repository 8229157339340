import { ChangeEvent } from "react";

export function useInputHandler<T = string>(
  valueCallback: (newValue: T) => void,
  convert?: (newValue: string) => T
) {
  return (event: ChangeEvent<HTMLInputElement>) => {
    const value = convert ? convert(event.target.value) : event.target.value;
    valueCallback(value as T);
  };
}
