import { addAlphaToRgb } from "src/utils/functions/addAlphaToRgb";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled from "styled-components/macro";

export const StyledModal = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

export const StyledBackdrop = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: ${extractThemeValue("background", (value) =>
    addAlphaToRgb(value, 0.8)
  )};
`;

export const StyledWrapper = styled.div`
  position: relative;
`;
