import { FC, memo } from "react";
import { useNavigate } from "react-router-dom";
import { StyledHoverableAppMenuElement } from "../../styles";
import { Props } from "./types";

export const LinkElement: FC<Props> = memo(function LinkElement(props) {
  const { label, to, ...otherProps } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    if (to) navigate(to);
  };

  return (
    <StyledHoverableAppMenuElement onClick={handleClick} {...otherProps}>
      {label}
    </StyledHoverableAppMenuElement>
  );
});
