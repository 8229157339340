import { LocalStorageItems, ThemeNames } from "src/types";
import { useMediaQueries } from "../useMediaQueries";

export const usePreferedTheme = (): ThemeNames => {
  const storageTheme = localStorage.getItem(LocalStorageItems.AppTheme);
  return storageTheme
    ? (storageTheme as ThemeNames)
    : useMediaQueries<ThemeNames>(
        ["(prefers-color-scheme: light)", "(prefers-color-scheme: dark)"],
        [ThemeNames.Light, ThemeNames.Dark],
        ThemeNames.Light
      );
};
