import styled from "styled-components/macro";

export const StyledIcon = styled.div`
  user-select: none;
  width: 28px;
  height: 28px;
  cursor: pointer;
  color: inherit;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
