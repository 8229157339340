import { FC, memo } from "react";
import { ReactComponent as DeleteIcon } from "src/assets/icons/deleteIcon.svg";
import { ReactComponent as EditIcon } from "src/assets/icons/editNoteIcon.svg";
import { ReactComponent as PreviewIcon } from "src/assets/icons/imageIcon.svg";
import { ReactComponent as OpenIcon } from "src/assets/icons/openInBrowserIcon.svg";
import { ReactComponent as VisibilityOffIcon } from "src/assets/icons/visibilityOffIcon.svg";
import { ReactComponent as VisibilityOnIcon } from "src/assets/icons/visibilityOnIcon.svg";
import { Icon } from "src/components/common/Icon";
import { IconLoader } from "src/components/common/IconLoader";
import {
  StyledButtonMenuPopover,
  StyledButtonMenu,
  StyledButton,
  StyledSeparator,
  DeleteStyledButton,
} from "./styles";
import { Props } from "./types";

export const VodListElementDropdownMenu: FC<Props> = memo(
  function VodListElementDropdownMenu(props) {
    const {
      visible,
      onHide,
      onOpen,
      onPreviewChange,
      onChange,
      onVisibilityChange,
      isVodHidden,
      isVisibilityLoading,
      onDelete,
      ...otherProps
    } = props;

    return (
      <StyledButtonMenuPopover
        {...otherProps}
        visible={visible}
        onHide={onHide}
        portal={false}
      >
        <StyledButtonMenu>
          <StyledButton onClick={onOpen}>
            Просмотреть
            <Icon>
              <OpenIcon />
            </Icon>
          </StyledButton>
          <StyledSeparator />
          <StyledButton onClick={onPreviewChange}>
            Заменить превью
            <Icon>
              <PreviewIcon />
            </Icon>
          </StyledButton>
          <StyledButton onClick={onChange}>
            Изменить
            <Icon>
              <EditIcon />
            </Icon>
          </StyledButton>
          <StyledButton onClick={onVisibilityChange}>
            {isVodHidden ? (
              <>
                Открыть доступ
                {!isVisibilityLoading ? (
                  <Icon>
                    <VisibilityOnIcon />
                  </Icon>
                ) : (
                  <IconLoader />
                )}
              </>
            ) : (
              <>
                Скрыть
                {!isVisibilityLoading ? (
                  <Icon>
                    <VisibilityOffIcon />
                  </Icon>
                ) : (
                  <IconLoader />
                )}
              </>
            )}
          </StyledButton>
          <StyledSeparator />
          <DeleteStyledButton onClick={onDelete}>
            Удалить
            <Icon>
              <DeleteIcon />
            </Icon>
          </DeleteStyledButton>
        </StyledButtonMenu>
      </StyledButtonMenuPopover>
    );
  }
);
