import { makeAutoObservable } from "mobx";
import { LocalStorageItems, ThemeNames } from "src/types";
import { DefaultTheme } from "styled-components/macro";
import { darkTheme, lightTheme } from "./constants";
import { Options } from "./types";

export class ThemeStore {
  public theme: DefaultTheme;

  public themeName: ThemeNames;

  public constructor(options: Options) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.themeName = options.theme;
    this.theme = this.getTheme(options.theme);
    localStorage.setItem(LocalStorageItems.AppTheme, options.theme);
  }

  public setTheme(theme: ThemeNames) {
    this.themeName = theme;
    this.theme = this.getTheme(theme);
    localStorage.setItem(LocalStorageItems.AppTheme, theme);
  }

  public toggleTheme() {
    this.themeName = this.getOppositeThemeName(this.themeName);
    this.theme = this.getTheme(this.themeName);
    localStorage.setItem(LocalStorageItems.AppTheme, this.themeName);
  }

  private getTheme(theme: ThemeNames) {
    return theme === "light" ? lightTheme : darkTheme;
  }

  private getOppositeThemeName(theme: ThemeNames) {
    return theme === "light" ? ThemeNames.Dark : ThemeNames.Light;
  }
}
