import { mixinScreenMaxWidth } from "src/assets/styles/mixins";
import { screenL, screenS } from "src/assets/styles/variables";
import { addAlphaToRgb } from "src/utils/functions/addAlphaToRgb";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled, { css } from "styled-components/macro";

export const StyledVodListElement = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: "preview content game controls";
  grid-template-columns: 125px 1fr 200px 33px;
  gap: 15px;
  align-items: center;
  justify-content: center;
  color: ${extractThemeValue("color")};
  border-bottom: 1px solid
    ${extractThemeValue("color", (value) => addAlphaToRgb(value, 0.05))};

  ${mixinScreenMaxWidth(
    screenL,
    css`
      grid-template-columns: 125px minmax(0, 1fr) 33px;
      grid-template-areas:
        "preview content controls"
        "preview game controls";
      gap: 5px 15px;
    `
  )};

  ${mixinScreenMaxWidth(
    screenS,
    css`
      align-items: start;
      padding: 5px 0px;
      grid-template-areas:
        "preview . controls"
        "content content controls"
        "game game controls";
    `
  )};
`;

export const StyledElementImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: preview;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledElementContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 8px 0px;
  grid-area: content;

  ${mixinScreenMaxWidth(
    screenL,
    css`
      padding: 0px;
      margin-top: 5px;
    `
  )};

  ${mixinScreenMaxWidth(
    screenS,
    css`
      margin-top: 0;
    `
  )};
`;

export const StyledElementTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledElementMeta = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${extractThemeValue("colorSecondary")};
`;

export const StyledElementGameCategory = styled.div`
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  grid-area: game;

  ${mixinScreenMaxWidth(
    screenL,
    css`
      margin-bottom: 5px;
    `
  )};

  ${mixinScreenMaxWidth(
    screenS,
    css`
      margin-bottom: 0;
    `
  )};
`;

export const StyledElementControls = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  grid-area: controls;
`;
