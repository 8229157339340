import { FC, FormEvent, memo, useCallback } from "react";
import { Switch } from "src/components/common/Switch";
import { StyledSwitchElement } from "./styles";
import { Props } from "./types";

export const SwitchElement: FC<Props> = memo(function SwitchElement(props) {
  const { label, checked, onChange, ...otherProps } = props;

  const handleClick = useCallback(
    (event: FormEvent<HTMLDivElement>) => {
      if (onChange) onChange(event);
    },
    [onChange]
  );

  return (
    <StyledSwitchElement {...otherProps} onClick={handleClick}>
      {label}
      <Switch checked={checked} />
    </StyledSwitchElement>
  );
});
