import { FC, memo } from "react";
import { IconLoader } from "src/components/common/IconLoader";
import { Error } from "src/utils/components/Error";
import { Modal } from "src/utils/components/Modal";
import {
  StyledModalContainer,
  StyledModalTitle,
  StyledModalList,
  StyledModalListElement,
  StyledModalListElementImageWrapper,
  StyledModalListElementTitle,
  StyledModalListElementButtonsSection,
  StyledModalListElementWarningButton,
  StyledModalListElementInlineButton,
} from "../../styles";
import { Props } from "./types";

export const DeleteModal: FC<Props> = memo(function DeleteModal(props) {
  const { vod, visible, isLoading, isError, onHide, onDelete } = props;

  if (!vod) return null;

  return (
    <Modal visible={visible} onHide={onHide}>
      <StyledModalContainer>
        <StyledModalTitle>Подтверждение</StyledModalTitle>
        <StyledModalList>
          <StyledModalListElement key={vod.id}>
            <StyledModalListElementImageWrapper>
              <img src={vod.previewUrl} alt="preview" />
            </StyledModalListElementImageWrapper>
            <StyledModalListElementTitle>
              {vod.title}
            </StyledModalListElementTitle>
          </StyledModalListElement>
        </StyledModalList>
        {isError && <Error>Произошла ошибка</Error>}
        <StyledModalListElementButtonsSection>
          <StyledModalListElementWarningButton
            disabled={isLoading}
            onClick={onDelete}
          >
            Удалить {isLoading && <IconLoader />}
          </StyledModalListElementWarningButton>
          <StyledModalListElementInlineButton onClick={onHide}>
            Отмена
          </StyledModalListElementInlineButton>
        </StyledModalListElementButtonsSection>
      </StyledModalContainer>
    </Modal>
  );
});
