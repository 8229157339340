import { useEffect, useState } from "react";

export function useMediaQueries<T>(
  queries: string[],
  values: T[],
  defaultValue: T
): T {
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));

  const getValue = () => {
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  };

  const [value, setValue] = useState(getValue);
  useEffect(() => {
    const handler = () => setValue(getValue);

    // Были проблемы с сафари, что в нем нет addEventListener
    mediaQueryLists.forEach((mql) =>
      !!mql.addEventListener
        ? mql.addEventListener("change", handler)
        : mql.addListener(handler)
    );

    return () =>
      mediaQueryLists.forEach((mql) =>
        !!mql.removeEventListener
          ? mql.removeEventListener("change", handler)
          : mql.removeListener(handler)
      );
  }, []);

  return value;
}
