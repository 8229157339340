import styled from "styled-components/macro";

export const StyledMainLayout = styled.div`
  position: relative;
  margin-top: ${({ theme }) => theme.headerHeight};
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  height: calc(100% - ${({ theme }) => theme.headerHeight});
  overflow: auto;
`;
