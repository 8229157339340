import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { EventEmitter } from "src/utils/classes/EventEmitter";
import { Events } from "src/utils/classes/Events";
import { getCookie } from "src/utils/functions/getCookie";
import { ProfileData } from "../ProfileStore/types";
import { VodData, VodEditParams } from "../VodsStore/types";
import { AuthResponse, EventsMap, ReauthResponse } from "./types";

export class Api {
  public events: Events<EventsMap>;

  private eventEmitter = new EventEmitter<EventsMap>();

  private client = axios.create({
    baseURL: "/api/v1",
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  });

  private token: string | null = null;

  private expiresAt: Date | null = null;

  public constructor() {
    this.events = new Events({ eventEmitter: this.eventEmitter });
  }

  public get hasCredentials(): boolean {
    return !!this.token || !!getCookie("uuid");
  }

  public async signIn(channel: string, password: string): Promise<ProfileData> {
    const response = await this.client.post<AuthResponse>("/auth/sign_in", {
      channel: channel,
      password: password,
    });
    const { user, token, expiresAt } = response.data;
    this.updateTokenData(token, expiresAt);
    return user;
  }

  public async signOut(): Promise<void> {
    await this.postWithAuthentication("/auth/sign_out");
    this.clearTokenData();
  }

  public async fetchVods(channel: string): Promise<VodData[]> {
    const response = await this.client.get<VodData[]>(
      `/vods?channel=${channel}`
    );
    return response.data;
  }

  public async fetchVodsAsOwner(channel: string): Promise<VodData[]> {
    const vodsData = await this.getWithAuthentication<VodData[]>(
      `/vods?channel=${channel}`
    );
    return vodsData;
  }

  public async fetchProfile(): Promise<ProfileData> {
    const profileData = await this.getWithAuthentication<ProfileData>(
      "/profile/me"
    );
    return profileData;
  }

  public async changeExpiredPassword(password: string): Promise<ProfileData> {
    const profileData = await this.postWithAuthentication<ProfileData>(
      "/auth/change_expired_password",
      { password }
    );
    return profileData;
  }

  public async changeVodVisibility(vodId: string, visibility: boolean) {
    const vodData = await this.postWithAuthentication<VodData>(
      `/vods/${vodId}/change_visibility`,
      { visibility }
    );
    return vodData;
  }

  public async uploadPreview(vodId: string, preview: File) {
    const form = new FormData();
    form.append("preview", preview);
    const vodData = await this.postWithAuthentication<VodData>(
      `/vods/${vodId}/upload_preview`,
      form,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    return vodData;
  }

  public async editVod(vodId: string, vodEditParams: VodEditParams) {
    const vodData = await this.postWithAuthentication<VodData>(
      `/vods/${vodId}/edit`,
      vodEditParams
    );
    return vodData;
  }

  public async deleteVod(vodId: string) {
    await this.postWithAuthentication(`/vods/${vodId}/delete`);
  }

  private async getWithAuthentication<T>(url: string): Promise<T> {
    try {
      if (this.checkIfTokenExpired()) await this.refreshToken();

      const response = await this.client.get<T>(url, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return response.data;
    } catch (error) {
      if (!this.checkIfUnauthorizedError(error)) this.clearTokenData();
      throw error;
    }
  }

  private async postWithAuthentication<T>(
    url: string,
    body?: Record<string, any>,
    config?: AxiosRequestConfig<any> | undefined
  ): Promise<T> {
    try {
      if (this.checkIfTokenExpired()) await this.refreshToken();

      const response = await this.client.post<T>(url, body, {
        ...config,
        headers: { ...config?.headers, Authorization: `Bearer ${this.token}` },
      });
      return response.data;
    } catch (error) {
      if (!this.checkIfUnauthorizedError(error)) this.clearTokenData();
      throw error;
    }
  }

  private async refreshToken(): Promise<ProfileData> {
    try {
      const response = await this.client.post<ReauthResponse>("/auth/reauth");
      const { user, token, expiresAt } = response.data;
      this.updateTokenData(token, expiresAt);
      this.eventEmitter.emit("reauth", user);
      return user;
    } catch (error: any) {
      this.clearTokenData();
      this.eventEmitter.emit("reauthError");
      throw error;
    }
  }

  private updateTokenData(token: string, expiresAt: string) {
    this.token = token;
    this.expiresAt = new Date(expiresAt);
  }

  private clearTokenData() {
    this.token = null;
    this.expiresAt = null;
  }

  private checkIfTokenExpired() {
    return (
      !this.token || !this.expiresAt || this.expiresAt.getTime() < Date.now()
    );
  }

  private checkIfUnauthorizedError(error: any): error is AxiosError {
    return (
      "response" in error &&
      "data" in error.response &&
      "statusCode" in error.response.data &&
      error.response.data.statusCode === 401
    );
  }
}
