import { Icon } from "src/components/common/Icon";
import { addAlphaToRgb } from "src/utils/functions/addAlphaToRgb";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled from "styled-components/macro";
import { TextPanel } from "../TextPanel";

export const StyledInfoIcon = styled(Icon)`
  flex: none;
  color: ${extractThemeValue("info")};
  cursor: inherit;
`;

export const StyledInfoContentTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.15em;
  color: ${extractThemeValue("color")};
`;

export const StyledInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  padding: 5px 0px;
  width: 100%;
  height: auto;
`;

export const StyledInfo = styled(TextPanel)<{ $wrap?: boolean }>`
  color: ${extractThemeValue("color")};
  background: ${extractThemeValue("info", (value) =>
    addAlphaToRgb(value, 0.1)
  )};
  cursor: ${({ $wrap }) => ($wrap ? "pointer" : "default")};
`;
