import { FC, memo } from "react";
import { ElementProps } from "src/utils/types/elementProps";
import { ReactComponent as EyeLockIcon } from "./images/EyeLock.svg";
import { StyledHiddenBadge } from "./styles";

export const HiddenBadge: FC<ElementProps> = memo(function HiddenBadge(
  props: ElementProps
) {
  return (
    <StyledHiddenBadge {...props}>
      <EyeLockIcon />
    </StyledHiddenBadge>
  );
});
