import { action, computed, makeObservable, observable } from "mobx";
import { BaseStore } from "src/utils/classes/BaseStore";
import { Nullable } from "src/utils/types/nullable";
import { Api } from "../Api";
import { Options, ProfileData } from "./types";

export class ProfileStore extends BaseStore {
  @observable.shallow
  private data: Nullable<ProfileData> = null;

  private api: Api;

  public constructor(options: Options) {
    super();
    makeObservable(this);
    this.api = options.api;
    this.subscribeApiListeners();
  }

  @computed
  public get isAuthorized() {
    return !!this.data;
  }

  @computed
  public get isPrivelleged() {
    return (
      this.isAuthorized &&
      !!this.role &&
      ["streamer", "admin"].includes(this.role)
    );
  }

  @computed
  public get id() {
    return this.data?.id;
  }

  @computed
  public get channel() {
    return this.data?.channel;
  }

  @computed
  public get isPasswordExpired() {
    return this.data?.isPasswordExpired;
  }

  @computed
  public get role() {
    return this.data?.role;
  }

  @action
  public async init() {
    if (!this.api.hasCredentials) return;
    this.processWithLoad(
      async () => {
        this.setData(await this.api.fetchProfile());
      },
      () => this.setData(null)
    );
  }

  @action.bound
  public async signIn(channel: string, password: string) {
    this.processWithLoad(
      async () => {
        this.setData(await this.api.signIn(channel, password));
      },
      () => this.setData(null)
    );
  }

  @action.bound
  public async signOut() {
    this.processWithLoad(
      async () => {
        await this.api.signOut();
        this.setData(null);
      },
      () => this.setData(null)
    );
  }

  @action.bound
  public async changeExpiredPassword(password: string) {
    if (!this.isPasswordExpired) return;
    this.processWithLoad(
      async () => {
        this.setData(await this.api.changeExpiredPassword(password));
      },
      () => this.setData(null)
    );
  }

  @action
  private setData(data: ProfileData | null) {
    this.data = data;
  }

  private subscribeApiListeners() {
    this.api.events.on("reauth", (profile) => this.setData(profile));
    this.api.events.on("reauthError", () => this.setData(null));
  }
}
