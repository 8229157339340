import { mixinHover } from "src/assets/styles/mixins";
import { addAlphaToRgb } from "src/utils/functions/addAlphaToRgb";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled, { css } from "styled-components/macro";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 475px;
  width: 100%;
  color: ${extractThemeValue("color")};
  background: ${extractThemeValue("backgroundPrimary")};
  padding: ${extractThemeValue("formsPadding")};
  border-radius: ${extractThemeValue("formsBorderRadius")};
  align-items: center;
`;

export const StyledTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  user-select: none;
  margin: 0;
`;

export const StyledPanel = styled.div`
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%;
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const StyledInputLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${extractThemeValue("colorSecondary")};
`;

export const StyledInput = styled.input`
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  outline: none;
  background: ${extractThemeValue("backgroundSecondary")};
  border: 2px solid ${extractThemeValue("backgroundSecondary")};
  color: ${extractThemeValue("color")};
  border-radius: 0.4em;
  padding: 0.5em 1em;
  transition: border 0.1s ease-out;

  ${mixinHover(css`
    border: 2px solid ${extractThemeValue("background")};
  `)}

  &:focus {
    border: 2px solid ${extractThemeValue("background")};
  }
`;

export const StyledButtonsSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
`;

export const StyledInlineButton = styled.button`
  font-size: 16px;
  color: ${extractThemeValue("colorSecondary")};
  background: transparent;
  cursor: pointer;
`;

export const StyledButton = styled.button`
  font-size: 18px;
  font-weight: 500;
  color: rgb(250, 250, 250);
  background: ${extractThemeValue("colorAccent")};
  padding: 0.5em 1em;
  border-radius: 0.2em;
  cursor: pointer;
  transition: background 0.1s ease-out;

  ${mixinHover(css`
    background: ${extractThemeValue("colorAccent", (value) =>
      addAlphaToRgb(value, 0.6)
    )};
  `)}

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;
