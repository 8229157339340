import { mixinHover } from "src/assets/styles/mixins";
import { extractThemeValue } from "src/utils/functions/extractThemeValue";
import styled, { css } from "styled-components/macro";

export const StyledAppMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${extractThemeValue("appMenuPadding")};
`;

export const StyledSeparator = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  margin: 5px 0px;
  background: ${extractThemeValue("backgroundSecondary")};
`;

export const StyledHoverableAppMenuElement = styled.div`
  width: 100%;
  padding: ${extractThemeValue("appMenuInputPadding")};
  transition: background 0.15s ease-out;
  cursor: pointer;

  ${mixinHover(css`
    background: ${extractThemeValue("backgroundSecondary")};
  `)}
`;
